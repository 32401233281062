import React, { useContext } from 'react';
import { DashboardContext } from '../components/DashboardContext.js';
import { Select, MenuItem, Typography } from '@material-ui/core';
import { useStyles } from './useStyles.js';

export default function BookmakerSelectionDropDown() {
  const { availableBookmakers, preferredBookmaker, setPreferredBookmaker } = useContext(DashboardContext);
  const classes = useStyles();

  const handleBookmakerChange = (event) => {
    let selectedBookmaker = event.target.value;
    setPreferredBookmaker(selectedBookmaker); 
  };

  const setProperNameBookmakers = (bookmaker) => {
    switch (bookmaker) {
      case 'betmgm':
        return 'BetMGM';
      case 'fanduel':
        return 'FanDuel';
      case 'draftkings':
        return 'DraftKings';
      case 'pinnacle':
        return 'Pinnacle';
      case 'williamhill_us':
        return 'Caesars';
      default:
        break;
    }
  }

 

  return (
    <div className={classes.CollectionSelectionDropDown} >
      <Typography variant="body1" >Bookmakers</Typography>
      <Select
        value={preferredBookmaker || ''}
        displayEmpty
        onChange={handleBookmakerChange}
        variant="outlined"
        className={classes.CollectionSelectionDropDownSelect}
      >
        {availableBookmakers.map((b, index) => {
          let name = setProperNameBookmakers(b)
          return (
          <MenuItem key={index} value={b}>
            {name}
          </MenuItem>
        )})}
      </Select>
     
    </div>
  );
}
