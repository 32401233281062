import React, { useState, useContext } from 'react';
import { Typography, TextField, Button, Box, IconButton, Table, TableContainer, Paper, TableHead, TableBody, TableRow, TableCell } from '@material-ui/core';
import { DashboardContext } from './../components/DashboardContext';
import { useStyles } from '../v2Components/useStyles';
import DeleteIcon from '@material-ui/icons/Delete';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

const CollectionEditForm = ({ setActiveView, handleDeleteItem, saveCollection }) => {
  const classes = useStyles();
  const {
    activeCollection, dictionary, activeCollectionName, activeCollectionDescription
  } = useContext(DashboardContext);
  
  const [name, setName] = useState(activeCollectionName);
  const [description, setDescription] = useState(activeCollectionDescription);

  function findKeyPathByUuid(data, uuid) {
    let resultPath = [];
    let found = false; // Flag to stop the search once the uuid is found
  
    function search(data, path = []) {
      if (found) return; // If the uuid has been found, stop the search
  
      if (Array.isArray(data)) {
        for (let i = 0; i < data.length; i++) {
          // No key to add to path for array indexes
          search(data[i], path);
        }
      } else if (typeof data === 'object' && data !== null) {
        for (const [key, value] of Object.entries(data)) {
          const newPath = path.concat(key); // Add the current key to the path
  
          // Check if the value is the uuid we're looking for
          if (value === uuid) {
            resultPath = newPath; // Save the current path
            found = true; // Mark as found to stop the search
            break;
          }
  
          // If the value is an object or array, search recursively
          if (typeof value === 'object') {
            search(value, newPath);
          }
        }
      }
    }
  
    search(data);
    return resultPath.length > 0 ? resultPath : null;
  }

  const handleSubmit = (e) => {
    // e.preventDefault(); // Prevent default form submission
    saveCollection(name, description);
  };

  return (
    <div className={classes.collectionEditDiv}>
    <IconButton onClick={() => setActiveView('Collections')}>
      <ArrowBackIosIcon fontSize="small" />
      <Typography variant="button">Back to My Collections</Typography>
    </IconButton>
    <Box component="form" onSubmit={handleSubmit} noValidate autoComplete="off">
      <TextField
        label="Collection Name"
        variant="outlined"
        fullWidth
        margin="normal"
        value={name}
        onChange={(e) => setName(e.target.value)}
      />
      <TextField
        label="Collection Description"
        variant="outlined"
        fullWidth
        margin="normal"
        value={description}
        onChange={(e) => setDescription(e.target.value)}
      />

      <Button type="submit" variant="contained" color="primary" style={{ marginTop: 16 }}>
        Save
      </Button>
    </Box>
    <TableContainer component={Paper} className={classes.collectionEditTableContainer}>
      <Table aria-label="comparison table">
        <TableHead>
          <TableRow>
            <TableCell align="left">Data Name</TableCell>
            <TableCell align="left">Category</TableCell>
            <TableCell align="right">Remove</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
        {activeCollection.map((item, index) => {
            let key = findKeyPathByUuid(dictionary, item)
            let name = key.pop()
            let category = key.join(' / ')
            return (
              <TableRow key={index}>
                <TableCell align="left">{name}</TableCell>
                <TableCell align="left">{category}</TableCell>
                <TableCell align="right">
                  <IconButton onClick={() => handleDeleteItem(item)} edge="end" aria-label="delete" className={classes.deleteButton}>
                    <DeleteIcon />
                  </IconButton> 
                </TableCell>  
              </TableRow>
          )}
        )}
        </TableBody>
      </Table>
    </TableContainer>
    </div>
  );
};

export default CollectionEditForm;
