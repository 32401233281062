import React, { useState, useContext } from 'react';
import { Modal, Typography, Paper, List, ListItem, ListItemIcon, ListItemText, IconButton } from '@material-ui/core';
import CancelTwoToneIcon from '@material-ui/icons/CancelTwoTone';
import { DashboardContext } from './../components/DashboardContext';
import { useStyles } from '../v2Components/useStyles';
import CollectionSelectionDropDown from './CollectionSelectionDropDown';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import AddNewCollectionButton from './AddNewCollectionButton';
import CollectionEditForm from './CollectionEditForm';

import DynamicTreeView from './DynamicTreeView';

const StatsExplorer = () => {
  const {
    activeLeague, dictionary, activeCollection, setActiveCollection,
    setActiveCollectionName, setActiveCollectionDescription,
    myCollections, setMyCollections, isDictionaryOpen, setDictionaryOpen,
    selectedStatData,
    expandedNodes, toggleNode
  } = useContext(DashboardContext)

  const classes = useStyles();
  
  const [activeView, setActiveView] = useState("Collections");

  const deleteCollection = (cName) => {
    const updatedCollections = myCollections.filter(collection => collection.name !== cName);
    if (updatedCollections.length === 0) {
      alert('May Not Delete Default Collection');
    } else {
      setMyCollections(updatedCollections);
    }
  }
  
  const editCollection = (cName) => {
    const collection = myCollections.find(collection => collection.name === cName);
    setActiveCollection(collection.stats);
    setActiveCollectionName(collection.name);
    setActiveCollectionDescription(collection.description);
    setActiveView('Collection');
  }

  const saveCollection = (newName, newDescription) => {
    setActiveCollectionName(newName);
    setActiveCollectionDescription(newDescription)
    
  };

  const CollectionsManager = () => {
    return (
      <div>
        <Typography variant="h6">My Collections</Typography>
        <AddNewCollectionButton />Start A New Collection
        <List>
          {myCollections.map((collection, index) => (
            <React.Fragment key={`collections${index}`}>
              <ListItem button onClick={(e) => { editCollection(collection.name) }}>
                <ListItemIcon>
                  <IconButton onClick={(e) => { deleteCollection(collection.name)}}>
                    <DeleteIcon />
                  </IconButton>
                  <IconButton onClick={(e) => { editCollection(collection.name)}}>
                    <EditIcon />
                  </IconButton>
                </ListItemIcon>
                <ListItemText
                  primary={<Typography variant="h6">{collection.name}</Typography>}
                  secondary={<Typography variant="body2" style={{ marginTop: '4px' }}>{collection.description}</Typography>}
                />
              </ListItem>
            </React.Fragment>
          ))}
        </List>
      </div>
    );
  };
  const handleDeleteItem = (itemToDelete) => {
    const updatedCollection = activeCollection.filter(item => item !== itemToDelete);
    setActiveCollection(updatedCollection);
  };
  
  const StatDetail = () => {
    if (!selectedStatData) return null;
    return (
      <div>
        <IconButton onClick={() => setActiveView('Collections')}>
        <ArrowBackIosIcon fontSize="small" />
          <Typography variant="button">Back to My Collections</Typography>
        </IconButton>
        <Typography variant="h5">
        {selectedStatData.Name}
      </Typography>
        <br />
        Category: {selectedStatData.NodePath.path.map((path, index, array) => (
        <span key={index} style={{ fontSize: 'medium' }}>
          {path.replace(/([A-Z])/g, ' $1').trim()}
          {index < array.length - 1 && ' / '}
        </span>
      ))}
      <Typography >
      <br></br>
        {selectedStatData.LongDescription}
      </Typography>
      </div>
    );
  }

  const StageArea = () => {
    switch (activeView) {
      case 'Collections':
        return <CollectionsManager />;
      case 'Collection':
        return <CollectionEditForm setActiveView={setActiveView} handleDeleteItem={handleDeleteItem} saveCollection={saveCollection} />
      case 'StatDetail':
        return <StatDetail />;
      default:
        return <CollectionsManager />;
    }
  };
  
  
  if (!dictionary) return null;

  return (
    <Modal open={isDictionaryOpen} onClose={() => setDictionaryOpen(false)}>
      <div className={classes.modalRoot}>
        <div className={classes.modalHeaderStyle}>
          <Typography variant="h6">{`${activeLeague} Data Explorer`}</Typography>
          <IconButton onClick={() => setDictionaryOpen(false)}>
            <CancelTwoToneIcon />
          </IconButton>
        </div>
        <div style={{ display: 'flex', height: 'calc(90vh - 48px)' }}>
          <List className={classes.listPanel} disablePadding>
            <CollectionSelectionDropDown setActiveView={setActiveView}/>
            <DynamicTreeView toggleNode={toggleNode} expandedNodes={expandedNodes} setActiveView={setActiveView}/>
          </List>
          <Paper className={classes.detailPanel}>
           <StageArea />
          </Paper>
        </div>
      </div>
    </Modal>
  );
};

export default StatsExplorer;
