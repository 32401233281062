import React, { useRef, useEffect } from 'react';
import { Button, Typography, Container } from '@material-ui/core';
import { useStyles } from './useStyles';
import { Link } from "./../util/router";

const HomeHeroSection = (props) => {
  const classes = useStyles();
  const videoRef = useRef(null);

  useEffect(() => {
    const handleVideoEnd = () => {
      const video = videoRef.current;
      video.currentTime = video.duration - 0.1; // Stop on the last frame
    };

    const video = videoRef.current;
    video.addEventListener('ended', handleVideoEnd);

    return () => {
      video.removeEventListener('ended', handleVideoEnd);
    };
  }, []);

  return (
    <div className={classes.heroSection}>
      <Container className={classes.content}>
        <Button
          component={Link}
          to={props.buttonPath}
          variant="contained"
          size="large"
          color={props.buttonColor}
        >
          {props.buttonText}
        </Button>
        <Typography variant="h2">
          <strong>StatFactory.io</strong>
        </Typography>

        <Typography variant="h5" component="h2" paragraph>
      Sports Stats for Gambling and Fantasy Sports, Made Simple
        </Typography>
      </Container>
      <video ref={videoRef} className={classes.video} autoPlay muted>
        <source src="/video/HomeHeroVideo.webm" type="video/webm" />
        Your browser does not support the video tag.
      </video>
    </div>
  );
};

export default HomeHeroSection;
