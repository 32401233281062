import React from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import PropTypes from 'prop-types';
import { useStyles } from './../v2Components/useStyles.js'

const CustomTooltip = ({ active, payload, label }) => {
  const classes = useStyles();
    if (active && payload && payload.length) {
      return (
        <div className={classes.tooltip}>
          <p>{new Date(label).toLocaleString()}</p>
          {payload.map((entry, index) => (
            <p key={index}>
              <span style={{ color: entry.color }}>{entry.name}: {entry.value}</span>
            </p>
          ))}
        </div>
      );
    }
    return null;
  };
  
  CustomTooltip.propTypes = {
    active: PropTypes.bool,
    payload: PropTypes.array,
    label: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.instanceOf(Date),
    ]),
  };

  const generateYAxisTicks = (min, max) => {
    // Calculate the range and ensure it's an absolute value
    const range = Math.abs(max - min);
  
    // Determine the step size, ensuring a minimum step of 1
    const step = Math.max(1, Math.floor(range / 10));
  
    // Calculate the start and end values rounded to the nearest step
    let start = Math.floor(min / step) * step;
    let end = Math.ceil(max / step) * step;

    if (start > end) {
      let temp = start;
      start = end;
      end = temp;
    }
  
    let ticks = [];
  
    // Generate ticks from start to end with the calculated step
    for (let i = start; i <= end; i += step) {
      ticks.push(i);
    }
  
    // If no ticks were generated (e.g., very close min and max), add the min and max
    if (ticks.length === 0) {
      ticks.push(Math.floor(min), Math.ceil(max));
    }
  
    return ticks;
  };

const MoneyLineChart = ({ data, preferredBookmaker, visitor, home }) => {
  const chartData = data.map(item => {
    const timestamp = item.timestamp;
    const visitorData = item[preferredBookmaker]?.MoneyLine?.[visitor];
    const homeData = item[preferredBookmaker]?.MoneyLine?.[home];
    return { timestamp, [visitor]: visitorData, [home]: homeData };
  }).filter(item => item[visitor] !== undefined && item[home] !== undefined);
  chartData.reverse();

  let bufferFraction = 0.3;
  const values = chartData.flatMap(d => [d[visitor], d[home]]);

  let maxY = Math.max(...values)
  let minY = Math.min(...values)
  let buffer = Math.max(Math.abs(maxY), Math.abs(minY)) * bufferFraction;

  // Adjust maxY and minY with the buffer
  maxY += buffer;
  minY -= buffer;

  const yAxisTicks = generateYAxisTicks(minY, maxY);
  // console.log(yAxisTicks)
  const formatTime = (timestamp) => {
    const date = new Date(timestamp);
    let hours = date.getHours();
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const ampm = hours >= 12 ? 'p' : 'a';
    
    hours = hours % 12;
    hours = hours ? hours : 12; // The hour '0' should be '12'
    
    return `${hours}:${minutes}${ampm}`;
  };
  

  return (
      <ResponsiveContainer width="100%" height={200}>
        <LineChart
          data={chartData}
        >
          <CartesianGrid strokeDasharray="5 7" />
          <XAxis dataKey="timestamp" tickFormatter={(timestamp) => formatTime(timestamp)} />
          <YAxis ticks={yAxisTicks} domain={[minY, maxY]} allowDataOverflow />
          <Tooltip content={<CustomTooltip />} />
          <Legend />
          <Line isAnimationActive={false} type="monotone" dataKey={visitor} stroke="#d88488" strokeWidth={3} dot={false} />
          <Line isAnimationActive={false} type="monotone" dataKey={home} stroke="#82ca9d" strokeWidth={3} dot={false} />
        </LineChart>
      </ResponsiveContainer>
  );
};

export default MoneyLineChart;
