import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';
import { useAuth } from "../util/auth.js"
import _ from 'lodash'

export const DashboardContext = createContext();

// Custom hook to facilitate accessing context data
export const useDashboard = () => useContext(DashboardContext);

const apiUrl = process.env.REACT_APP_API_URL;
const freeRows = 4

export const DataProvider = ({ children }) => {
  const [leagues, setLeagues] = useState([]);
  const [activeLeague, setActiveLeague] = useState(null)
  const [activeView, setactiveView] = useState('Overview')
  const [dictionary, setDictionary] = useState([])
  const [activeCollection, setActiveCollection] = useState([]);
  const [activeCollectionName, setActiveCollectionName] = useState(null)
  const [activeCollectionDescription, setActiveCollectionDescription] = useState(null);
  const [myCollections, setMyCollections] = useState([])
  const [odds, setOdds] = useState([])
  const [isDictionaryOpen, setDictionaryOpen] = useState(false)
  const [gameProps, setGameProps] = useState(null)
  const [gameData, setGameData] = useState(null)
  const [preferredBookmaker, setPreferredBookmaker] = useState('draftkings');
  const [availableBookmakers, setAvailableBookmakers] = useState([]);
  const [selectedStat, setSelectedStat] = useState(null)
  const [selectedStatData, setSelectedStatData] = useState(null)
  const [expandedNodes, setexpandedNodes] = useState(['root'])

  const auth = useAuth()
  const fetchFromAPI = async (endpoint, method = 'GET', data = {}) => {
    try {
      const options = {
        method,
        headers: { 'Content-Type': 'application/json' },
        body: method !== 'GET' ? JSON.stringify(data) : undefined,
      };
      let t = new Date().getTime()
      const response = await fetch(`${apiUrl}/${endpoint}?${t}`, options);
      if (!response.ok) throw new Error('Network response was not ok')
      if (endpoint === 'userCollections') return response
      else return response.json();
    } catch (error) {
      console.error(`Error fetching ${endpoint}:`, error);
    }
  };

  const toggleNode = useCallback((nodeIds) => {
    setexpandedNodes(nodeIds);
}, []);
  //Fetch the leagues from the API and set the state.
  useEffect(() => {
    const fetchLeagues = async () => {
      try {
        const _leagues = await fetchFromAPI('leagues');
        setLeagues(_leagues);
      } catch (error) {
        console.error('Error fetching leagues:', error);
      }
    };
    fetchLeagues();
  }, []);
  

  //If activeLeague changes, update the dictionary and get the games list.
  useEffect(() => {
    if (activeLeague && auth.user) {
      setSelectedStat(null);
      setSelectedStatData(null)

      if (auth.user) {
        const fetchOdds = async () => {
          fetchFromAPI('odds', 'POST', { league: activeLeague }).then((data) => {
            if (data.length > 0) {
              let bookmakersArray = []
              let bookmakersToProcess = Object.keys(data[0].Odds);
              bookmakersToProcess.forEach((bookmaker) => {
                  if (bookmaker !== 'timestamp') {
                    bookmakersArray.push(bookmaker);
                  }
                });
                setAvailableBookmakers(bookmakersArray);
                setOdds(data);
              }
          }).catch((error) => {
            console.error('Error fetching odds:', error);
          });
        
        }

        fetchOdds();
        const intervalId = setInterval(fetchOdds, 60000);
        


        fetchFromAPI('dictionary', 'POST', { league: activeLeague, user: auth.user.id})
        .then((data) => {
          setDictionary(data.dictionary)
          setMyCollections(data.collections)
          setActiveCollection(data.collections[0].stats)
          setActiveCollectionName(data.collections[0].name)
          setActiveCollectionDescription(data.collections[0].description)
        }).catch((error) => {
          console.error('Error fetching dictionary:', error);
        });

        return () => clearInterval(intervalId);

      }
    }
  }, [activeLeague, auth.user])

  useEffect(() => {
    if (selectedStat) {
      fetchFromAPI('def', 'POST', { league: activeLeague, stat: selectedStat })
        .then((data) => {
          setSelectedStatData(data);
        }).catch((error) => {
          console.error('Error fetching stat definition:', error);
        });
    }
  }, [selectedStat, activeLeague])

  //IF the gameProps or activeCollection changes, update the gameData.
  useEffect(() => {
    const dataToSend = { league: activeLeague, game: gameProps, filter: activeCollection };

    const fetchGameDetails = async () => {
      const gameDetails = await fetchFromAPI(`odds/game`, "POST", dataToSend)
      let bookmakersArray = []
      let bookmakersToProcess = Object.keys(gameDetails.odds[0]);
      bookmakersToProcess.forEach((bookmaker) => {
        if (bookmaker !== 'timestamp') {
          bookmakersArray.push(bookmaker);
        }
      });
      
      setAvailableBookmakers(bookmakersArray);
      setGameData(gameDetails);
        
    };

    // Regular expression to match UUID format
    const uuidFormat = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
    let intervalId = null;

    if (gameProps && uuidFormat.test(gameProps)) {
      fetchGameDetails(); // Initial call before setting interval
      intervalId = setInterval(fetchGameDetails, 60000); // Set interval to 1 minute
    }

    // Cleanup function to clear the interval when gameProps changes or component unmounts
    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [gameProps, activeCollection, activeLeague])

  // If any of: [activeCollection, activeCollectionName, activeCollectionDescription]
  // change, setMyCollections to update the collection data
  useEffect(() => {
    if (!activeCollectionName || !activeCollectionDescription || myCollections.length <1 ) return;
    let newCollections = myCollections.map((collection) => {
      if (collection.stats === activeCollection) {
        return {
          name: activeCollectionName,
          stats: activeCollection,
          description: activeCollectionDescription,
        };
      } else {
        return collection;
      }
    });
    setMyCollections(newCollections);
  }, [activeCollectionName, activeCollectionDescription]);

  useEffect(() => {
    if (!activeCollectionName || !activeCollectionDescription || myCollections.length <1 ) return;
    let newCollections = myCollections.map((collection) => {
      if (collection.name === activeCollectionName) {
        return {
          name: activeCollectionName,
          stats: activeCollection,
          description: activeCollectionDescription,
        };
      } else {
        return collection;
      }
    });
    setMyCollections(newCollections);
  }, [activeCollection]);
  
  useEffect(() => {
    const debounceFetchFromAPI = _.debounce((collections) => {
      if (auth.user && activeLeague) {
        fetchFromAPI('userCollections', 'POST', {
            league: activeLeague,
            user: auth.user.id,
            collections,
          })
          .catch((error) => {
            console.error('Error updating user collections:', error);
          })
      }
    }, 1000);
  
    debounceFetchFromAPI(myCollections);
  
    return () => {
      debounceFetchFromAPI.cancel();
    };
  }, [myCollections, auth.user, activeLeague]);

  return (
    <DashboardContext.Provider value={{
      freeRows, leagues, setLeagues,
      activeLeague, setActiveLeague,
      activeView, setactiveView,
      dictionary, setDictionary,
      preferredBookmaker, setPreferredBookmaker,
      odds, setOdds,
      isDictionaryOpen, setDictionaryOpen,
      gameProps, setGameProps,
      gameData, setGameData,
      myCollections, setMyCollections,
      activeCollection, setActiveCollection,
      activeCollectionName, setActiveCollectionName,
      activeCollectionDescription, setActiveCollectionDescription,
      availableBookmakers,
      selectedStat, setSelectedStat,
      selectedStatData, setSelectedStatData,
      expandedNodes, toggleNode,
    }}>
      {children}
    </DashboardContext.Provider>
  );
};
