import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { Box, List, ListItem, ListItemIcon, ListItemText, Typography } from '@material-ui/core';
import AccountTreeTwoToneIcon from '@material-ui/icons/AccountTreeTwoTone';
import ToggleOnTwoToneIcon from '@material-ui/icons/ToggleOnTwoTone';
import DynamicFeedTwoToneIcon from '@material-ui/icons/DynamicFeedTwoTone';
import AssessmentTwoToneIcon from '@material-ui/icons/AssessmentTwoTone';
import SportsFootballTwoToneIcon from '@material-ui/icons/SportsFootballTwoTone';
import GetAppTwoToneIcon from '@material-ui/icons/GetAppTwoTone';

const useStyles = makeStyles((theme) => ({
    boldText: {
        fontWeight: 'bold',
    },
    container: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-around',
        alignItems: 'center', // Center vertically
        // padding: '20px',
        // paddingTop: '0px',
        maxWidth: '1400px',
    },
    heroImage5: {
      width: '100%',
      height: 'auto',
      backgroundColor: '#e0e0e0',
      margin: theme.spacing(0),
      marginTop: theme.spacing(6),
      // display: 'block',
    },       
    imageTextContainer: {
      position: 'relative',
      display: 'inline-block',
    },
    heroText: {
      position: 'absolute',
      top: '40%',
      left: theme.spacing(5),
      color: '#fff',
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      padding: theme.spacing(2),
      borderRadius: theme.shape.borderRadius,
    },
    heroButton: {
      position: 'absolute',
      top: '60%',
      left: theme.spacing(10),
      backgroundColor: '#cc0000',
        color: 'white',
        padding: '10px 20px',
      borderRadius: theme.shape.borderRadius,
      border: 0
    },
}));


const IconsVideo = () => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Box className={classes.heroContainer}>
        <Box className={classes.imageTextContainer}>
          <img src='/images/overviewHero.png' alt='Stats Overview' className={classes.heroImage5} />
        <Typography variant="h4" className={classes.heroText}>
            Sports stats made simple
          </Typography>
          <Button variant="outlined" className={classes.heroButton}>Free 7 Day Trial</Button>
        </Box>
      </Box>
    </div>
  );
};

export default IconsVideo;
