import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { List, ListItem, ListItemIcon, ListItemText, Typography } from '@material-ui/core';
import AccountTreeTwoToneIcon from '@material-ui/icons/AccountTreeTwoTone';
import ToggleOnTwoToneIcon from '@material-ui/icons/ToggleOnTwoTone';
import DynamicFeedTwoToneIcon from '@material-ui/icons/DynamicFeedTwoTone';
import AssessmentTwoToneIcon from '@material-ui/icons/AssessmentTwoTone';
import SportsFootballTwoToneIcon from '@material-ui/icons/SportsFootballTwoTone';
import GetAppTwoToneIcon from '@material-ui/icons/GetAppTwoTone';

const useStyles = makeStyles((theme) => ({
    boldText: {
        fontWeight: 'bold',
    },
    container: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-around',
        alignItems: 'center', // Center vertically
        padding: '20px',
        [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        },
        maxWidth: '1400px',
    },        
    videoContainer: {
        position: 'relative',
        paddingBottom: '60%', // 16:9 aspect ratio
        height: 0,
        overflow: 'hidden',
        width: '500px',
        backgroundColor: '#333223',
        maxWidth: '100%',
        // background: '#000',
        marginBottom: '20px',
    },
    iframe: {
        width: '100%',
        height: '300px',
        border: 0,
    },
    sidebar: {
        display: 'flex',
        flexDirection: 'column',
        // width: '40%',
        justifyContent: 'space-around',
        alignItems: 'center', // Center vertically
        // padding: '10px',
        [theme.breakpoints.down('sm')]: {
        width: '90%',
        order: 2,
        marginTop: '20px',
        },
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '60%',
        // padding: '15px',
        [theme.breakpoints.down('sm')]: {
        width: '90%',
        order: 1,
        },
    },
    button: {
        marginTop: '20px',
        backgroundColor: '#cc0000',
        color: 'white',
        padding: '10px 20px',
        border: 0
    },
    
    listItem: {
        display: 'flex',
        alignItems: 'center',
    },
}));

const VideoEmbed = () => {
    const classes = useStyles();
    let videoId = 'DcYk0iasigk'
    videoId = 'RUrbQuVpz50'
    return (
      <div className={classes.videoContainer}>
        <iframe
          className={classes.iframe}
          src={`https://www.youtube.com/embed/${videoId}`}
          title="How To Use Statfactory.io"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </div>
    );
  };

const IconsVideo = () => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div className={classes.sidebar}>
        <Typography variant="h5" className={classes.boldText}>Choose From Hundreds of Stats</Typography>
        <List>
          <ListItem className={classes.listItem}>
            <ListItemIcon><AccountTreeTwoToneIcon style={{ color: '#ff6600' }}/></ListItemIcon>
            <ListItemText primary="Easy To Navigate" />
          </ListItem>
          <ListItem className={classes.listItem}>
            <ListItemIcon><ToggleOnTwoToneIcon style={{ color: '#008000' }}/></ListItemIcon>
            <ListItemText primary="Add Your Stats" />
          </ListItem>
          <ListItem className={classes.listItem}>
            <ListItemIcon><DynamicFeedTwoToneIcon style={{ color: '#9900ff' }}/></ListItemIcon>
            <ListItemText primary="Build Collections" />
          </ListItem>
          <ListItem className={classes.listItem}>
            <ListItemIcon><AssessmentTwoToneIcon style={{ color: '#cc0000' }}/></ListItemIcon>
            <ListItemText primary="Instant Game Reports" />
          </ListItem>
          <ListItem className={classes.listItem}>
            <ListItemIcon><SportsFootballTwoToneIcon style={{ color: '663300' }}/></ListItemIcon>
            <ListItemText primary="Your Sportsbook Odds" />
          </ListItem>
          <ListItem className={classes.listItem}>
            <ListItemIcon><GetAppTwoToneIcon style={{ color: '#3300FF' }}/></ListItemIcon>
            <ListItemText primary="Download Reports" />
          </ListItem>
        </List>
      </div>
      <div className={classes.content}>
        <div className={classes.placeholder}><VideoEmbed /></div>
        <Button variant="outlined" className={classes.button}>Free 7 Day Trial</Button>
      </div>
    </div>
  );
};

export default IconsVideo;
