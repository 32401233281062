import React, { useCallback, useState, useContext } from 'react';
import { sortableContainer, sortableElement, sortableHandle } from 'react-sortable-hoc';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@material-ui/core';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator'; // Import Material UI icon for drag handle
import { arrayMoveImmutable as arrayMove } from 'array-move';
import { DashboardContext } from '../components/DashboardContext';
import LockIcon from '@material-ui/icons/Lock';
import { useAuth } from "../util/auth.js";
// Step 2: Create a Draggable Handle Component





function ComparisonTable() {
  const { setActiveCollection,
    gameData, setGameData, freeRows
  } = useContext(DashboardContext);
  
  const [tableData, setTableData] = useState(gameData.requestedStats);
  
  const auth = useAuth()
  const DragHandle = sortableHandle(() => <DragIndicatorIcon style={{ cursor: 'grab' }} />);

  const SortableItem = sortableElement(({ key, entry, idx, plan, index  }) => {
      const DisplayValue = ( value ) => {
        const isFloat = (n) => {
            return Number(n) === n && n % 1 !== 0;
        };
        return isFloat(value) ? value.toFixed(2) : value;
      };

    let homeValue = entry.home && entry.home.length > 0 ? DisplayValue(entry.home[entry.home.length - 1]) : 0
    let visitorValue = entry.visitor && entry.visitor.length > 0 ? DisplayValue(entry.visitor[entry.visitor.length - 1]) : 0
    let diff = DisplayValue(visitorValue - homeValue)
    return (
      <TableRow key={entry.UUID}>
        <TableCell>
          <DragHandle />
        </TableCell>
        <TableCell component="th" scope="row">
          {entry.nodePath.map((path, index, array) => (
            <span key={index} style={{ fontSize: 'medium' }}>
              {path.replace(/([A-Z])/g, ' $1').trim()}
              {index < array.length - 1 && ' / '}
            </span>
          ))}
          <br />
          <span style={{ fontSize: 'large' }}>
            {entry.name}
            </span>
        </TableCell>
        <TableCell align="right">{plan ? visitorValue : !plan && idx < freeRows ? visitorValue : <><LockIcon /></>}</TableCell>
        <TableCell align="right">{plan ? diff : !plan && idx < freeRows ? diff : <><LockIcon /></>}</TableCell>
        <TableCell align="right">{plan ? homeValue : !plan && idx < freeRows ? homeValue : <><LockIcon /></>}</TableCell>
      </TableRow>
  )})
  
  const SortableContainer = sortableContainer(({ children }) => {
    return <TableBody>{children}</TableBody>;
  })


  const onSortEnd = useCallback(({ oldIndex, newIndex }) => {
    setTableData((currentTableData) => {
      const newTableData = arrayMove(currentTableData, oldIndex, newIndex);
      const sortedUUIDs = newTableData.map(row => row.id);
      setGameData(currentGameData => ({ ...currentGameData, requestedStats: newTableData }));
      setActiveCollection(sortedUUIDs);
      return newTableData; // Important to return the new state here
    });
  }, [setGameData, setActiveCollection]);
  
  


  return (
    <TableContainer component={Paper}>
      <Table aria-label="comparison table">
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell> Stat Name</TableCell>
            <TableCell align="right">Visitor: {gameData.visitor.fullName}</TableCell>
            <TableCell align="right">Visitor +/-</TableCell>
            <TableCell align="right">Home: {gameData.home.fullName}</TableCell>
          </TableRow>
        </TableHead>
      <SortableContainer onSortEnd={onSortEnd} useDragHandle>
      {tableData.map((entry, index) => {
          return (
            <SortableItem key={`item-${entry.id}`} index={index} idx={index} entry={entry} plan={auth.user.planIsActive}/>
          )
        }
      )}
      </SortableContainer>
      </Table>
    </TableContainer>
  );
}

export default ComparisonTable;
