import React, {useContext } from 'react';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import { DashboardContext } from '../components/DashboardContext';
// Step 2: Create a Draggable Handle Component





const DataDownloadButtons = ({ plan, data, visitor, vSpread, vMoneyline, home, hSpread, hMoneyline, overUnder, eventTime, preferredBookmaker }) => {
  const { freeRows } = useContext(DashboardContext);
  const downloadXLSX = () => {
      
      const headerRow = ['Data Field', 'Data Category',  `Away: ${visitor}`, `Home: ${home}`, `Game Time: ${"-"}`]
      const additionalRows = [
      ["Current Time",`${new Date().toLocaleDateString()}`, '' , `${new Date().toLocaleTimeString()}`], 
      ["Game Time",`${new Date(eventTime).toLocaleDateString()}`, '' , `${new Date(eventTime).toLocaleTimeString()}`], 
      ["",`VISITOR`, 'at' , `HOME`], 
      ["",`${visitor}`, '' , `${home}`], 
      [], // Blank row for separation
      [ 'Preferred Bookmaker', preferredBookmaker, "", ""],
      [ 'Moneyline', vMoneyline, "", hMoneyline],
      ['Spread:', vSpread.point, vSpread.price, hSpread.point, hSpread.price],
      ['Over/Under', overUnder.Over.point, overUnder.Over.price, overUnder.Under.point, overUnder.Under.price],
      [], // Blank row for separation
      headerRow // The header row for the main data
    ]
      console.log(data)
      let reshapedData = data.map((row, index) => {
        let categoryTree = row.nodePath
          .map(path => path.replace(/([A-Z])/g, ' $1').trim())
          .join('-');
        if (plan || (!plan && index < freeRows)) {
          return  [row.name, categoryTree,  row.visitor[row.visitor.length-1], row.home[row.home.length-1]]

        } else return [row.name, categoryTree,  'Subscribe', 'Subscribe']
      })
      
      const combinedData = [
        ...additionalRows,
        ...reshapedData
      ]
      const worksheet = XLSX.utils.json_to_sheet(combinedData) //reshapedData??
      const workbook = XLSX.utils.book_new();
      let time = new Date(eventTime).toLocaleTimeString().replace(/:/g, '-');
      let date = new Date(eventTime).toLocaleDateString().replace(/\//g, '-');
      XLSX.utils.book_append_sheet(workbook, worksheet, `${visitor.slice(0,4)}-${home.slice(0,4)}`);
      XLSX.writeFile(workbook, `${date}_${visitor}_at_${home}_${time}.xlsx`);
    }

    const downloadCSV = () => {
      const headerRow = ['Data Field', 'Data Category',  `Away: ${visitor}`, `Home: ${home}`, `Game Time: ${"-"}`]
      const additionalRows = [
        ["Current Time",`${new Date().toLocaleDateString()}`, '' , `${new Date().toLocaleTimeString()}`], 
        ["Game Time",`${new Date(eventTime).toLocaleDateString()}`, '' , `${new Date(eventTime).toLocaleTimeString()}`], 
        ["",`VISITOR`, 'at' , `HOME`], 
        ["",`${visitor}`, '' , `${home}`], 
        [], // Blank row for separation
        [ 'Preferred Bookmaker', preferredBookmaker, "", ""],
        [ 'Moneyline', vMoneyline, "", hMoneyline],
        ['Spread:', vSpread.point, vSpread.price, hSpread.point, hSpread.price],
        ['Over/Under', overUnder.Over.point, overUnder.Over.price, overUnder.Under.point, overUnder.Under.price],
        [], // Blank row for separation
        headerRow // The header row for the main data
      ]
        let reshapedData = data.map((row, index) => {
          
          let categoryTree = row.nodePath
            .map(path => path.replace(/([A-Z])/g, ' $1').trim())
            .join('-');
            if (plan || (!plan && index < freeRows)) {
              return  [row.name, categoryTree,  row.visitor[row.visitor.length-1], row.home[row.home.length-1]]
    
            } else return [row.name, categoryTree,  'Subscribe', 'Subscribe']
        })
    
    const combinedData = [
      ...additionalRows,
      ...reshapedData
    ]

    const dataRows = combinedData.map(row => Object.values(row).join(','))
      
      const csvData = [
        'Actual Data', // Add a header for the actual data
        headerRow, 
        ...dataRows, 
        '', // Blank row
        ].join('\n');
      let time = new Date(eventTime).toLocaleTimeString().replace(/:/g, '-');
    let date = new Date(eventTime).toLocaleDateString().replace(/\//g, '-');
      const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8' });
      saveAs(blob, `${date}_${visitor}_at_${home}_${time}.csv`);
    }  

    function DownloadCSVButton({ downloadCSV }) {
      return (
         <Button
         variant="contained"
         color="primary"
         startIcon={<CloudDownloadIcon />}
         onClick={downloadCSV}
       >
         Download CSV
       </Button>
      );
    }
    function DownloadXLSXButton({ downloadXLSX }) {
      return (
        <Button
          variant="contained"
          color="primary"
          startIcon={<CloudDownloadIcon />}
          onClick={downloadXLSX}
        >
          Download XLSX
        </Button>
      );
    }
    return (
      <Box display="flex" justifyContent="flex-start" alignItems="center" mb={2}>
        <Box mr={2}> {/* marginRight */}
          <DownloadCSVButton downloadCSV={downloadCSV} />
        </Box>
          <DownloadXLSXButton downloadXLSX={downloadXLSX} />
      </Box>
    );
    
  }


export default DataDownloadButtons;
