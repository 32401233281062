import React, { useEffect, useContext, useState } from 'react';
import { DashboardContext } from '../components/DashboardContext.js';
import { IconButton, Collapse, Card, CardContent, Typography } from '@material-ui/core';
import CountdownClock from './CountdownClock.jsx';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import MoneyLineChart from './MoneyLineChart.jsx';
import SpreadLineChart from './SpreadsLineChart.jsx';
import DataDownloadButtons from '../coreComponents/DataDownloadButtons.jsx';
import ComparisonTable from '../coreComponents/ComparisonTable.jsx';
import TotalsLineChart from './TotalsLineChart.jsx';
import CollectionSelectionDropDown from './CollectionSelectionDropDown.jsx';
import BookmakerSelectionDropDown from './BookmakerSelectionDropDown.jsx';
import { useStyles } from './../v2Components/useStyles.js'; // Adjust the import path accordingly
import TeamOddsRow from './TeamOddsRow.jsx';
import { useAuth } from "../util/auth.js";



const Tray = ({ id, title, children }) => {
  const [isOpen, setIsOpen] = useState(JSON.parse(localStorage.getItem(id)) || true);

  useEffect(() => {
    localStorage.setItem(id, isOpen);
  }, [id, isOpen]);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <Card style={{ marginBottom: 5 }}>
     <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'flex-start', alignItems: 'center', cursor: 'pointer', padding: 8, paddingLeft: 16 }} onClick={handleToggle}>
                <Typography variant="h6" style={{ marginRight: 8 }}>{title}</Typography>
                <IconButton>
                    {isOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </IconButton>
                {title === "Stats" ? <CollectionSelectionDropDown /> : title === "Odds" ? <BookmakerSelectionDropDown /> : null}
            </div>
      <Collapse in={isOpen}>
        <div style={{ padding: 8 }}>
          {children}
        </div>
      </Collapse>
    </Card>
  );
};

export default function GameDetail() {
  const auth = useAuth()
  const classes = useStyles();
  const { gameData, preferredBookmaker } = useContext(DashboardContext);
  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit', timeZoneName: 'short' };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  if (!gameData) return null;
  const moneyLinePresent = !!(
    gameData.odds?.[0]?.[preferredBookmaker]?.MoneyLine?.[gameData.visitor.fullName] !== undefined &&
    gameData.odds?.[0]?.[preferredBookmaker]?.MoneyLine?.[gameData.home.fullName] !== undefined
  );

  const spreadPresent = !!(
    gameData.odds?.[0]?.[preferredBookmaker]?.Spreads?.[gameData.visitor.fullName]?.point !== undefined &&
    gameData.odds?.[0]?.[preferredBookmaker]?.Spreads?.[gameData.home.fullName]?.point !== undefined
  );

  const OUPresent = !!(
    gameData.odds?.[0]?.[preferredBookmaker]?.Totals?.Over !== undefined &&
    gameData.odds?.[0]?.[preferredBookmaker]?.Totals?.Under !== undefined
  );
  
  let team1 = {
    teamName: gameData.visitor.fullName,
    league: `${gameData.visitor.league || gameData.visitor.conference || ""} ${gameData.visitor.division}`,
    moneylineOdds: moneyLinePresent ? gameData.odds[0][`${preferredBookmaker}`].MoneyLine[gameData.visitor.fullName] : 'N/A',
    spreadPoint: spreadPresent ? gameData.odds[0][`${preferredBookmaker}`].Spreads[gameData.visitor.fullName].point : 'N/A',
    spreadPrice: spreadPresent ? gameData.odds[0][`${preferredBookmaker}`].Spreads[gameData.visitor.fullName].price : 'N/A',
    totalsPoints: OUPresent ? gameData.odds[0][`${preferredBookmaker}`].Totals.Over.point : 'N/A',
    totalsPrice: OUPresent ? gameData.odds[0][`${preferredBookmaker}`].Totals.Over.price : 'N/A',
    primaryColor: gameData.visitor.primaryColor,
    secondaryColor: gameData.visitor.secondaryColor,
    results: !Array.isArray(gameData.results) ? null : gameData.results[0].name === gameData.visitor.fullName ? `${gameData.results[0].score}` : `${gameData.results[1].score}`
  };
  
  let team2 = {
    teamName: gameData.home.fullName,
    league: `${gameData.home.league || gameData.home.conference || ""} ${gameData.home.division}`,
    moneylineOdds: moneyLinePresent ? gameData.odds[0][`${preferredBookmaker}`].MoneyLine[gameData.home.fullName] : 'N/A',
    spreadPoint: spreadPresent ? gameData.odds[0][`${preferredBookmaker}`].Spreads[gameData.home.fullName].point : 'N/A',
    spreadPrice: spreadPresent ? gameData.odds[0][`${preferredBookmaker}`].Spreads[gameData.home.fullName].price : 'N/A',
    totalsPoints: OUPresent ? gameData.odds[0][`${preferredBookmaker}`].Totals.Under.point*-1 : 0,
    totalsPrice: OUPresent ? gameData.odds[0][`${preferredBookmaker}`].Totals.Under.price : 0,
    primaryColor: gameData.home.primaryColor,
    secondaryColor: gameData.home.secondaryColor,
    results: !Array.isArray(gameData.results) ? null : gameData.results[0].name === gameData.home.fullName ? `${gameData.results[0].score}` : `${gameData.results[1].score}`
  };
  

  return (
    <Card>
      <CardContent>
        <Typography variant="subtitle1" color="textSecondary">
          {formatDate(gameData.eventTime) }
          </Typography>
          <CountdownClock eventTime={gameData.eventTime}/>

        {/* {gameData.results[0].name === gameData.visitor.fullName ? `${gameData.results[0].score} - ${gameData.results[1].score}` : `${gameData.results[1].score} - ${gameData.results[0].score}`}   */}
        <TeamOddsRow team={team1}/>
        <TeamOddsRow team={team2}/>
        <div className={classes.flexRowContainer}>
              <div className={classes.flexItem}>
                <Tray id="statisticalInsights" title="Stats">
                
                <DataDownloadButtons 
                  data={gameData.requestedStats} 
                  visitor={gameData.visitor.fullName}
                  vSpread={spreadPresent ? gameData.odds[0][`${preferredBookmaker}`].Spreads[gameData.visitor.fullName] : { point: 'N/A', price: 'N/A' }}
                  vMoneyline={moneyLinePresent ? gameData.odds[0][`${preferredBookmaker}`].MoneyLine[gameData.visitor.fullName] : 'N/A'}
                  home={gameData.home.fullName} 
                  hSpread={spreadPresent ? gameData.odds[0][`${preferredBookmaker}`].Spreads[gameData.home.fullName] : { point: 'N/A', price: 'N/A' }}
                  hMoneyline={moneyLinePresent ? gameData.odds[0][`${preferredBookmaker}`].MoneyLine[gameData.home.fullName] : 'N/A'}
                  overUnder={OUPresent ? gameData.odds[0][`${preferredBookmaker}`].Totals : {Over: {point: 'N/A', price: 'N/A'}, Under: {point: 'N/A', price: 'N/A'}}}
                  eventTime={new Date(gameData.eventTime)}
                  plan={auth.user.planIsActive}
                  preferredBookmaker={preferredBookmaker}/>
                <ComparisonTable 
                  plan={auth.user.planIsActive}
                  initialTableData={gameData.requestedStats} visitor={gameData.visitor.fullName} home={gameData.home.fullName} />
                </Tray>
              </div>
          <div className={classes.flexItem}>
            <Tray id="odds" title="Odds">
            
              
              <Tray id="moneyline" title="Moneyline">
                <MoneyLineChart data={gameData.odds} preferredBookmaker={preferredBookmaker} visitor={gameData.visitor.fullName} home={gameData.home.fullName} visitorColors={{
                  primaryColor: gameData.visitor.primaryColor,
                  secondaryColor: gameData.visitor.secondaryColor
                }} homeColors={{
                  primaryColor: gameData.home.primaryColor,
                  secondaryColor: gameData.home.secondaryColor
                }}/>
              </Tray>
              <Tray id="spread" title="Spread">
                <SpreadLineChart data={gameData.odds} preferredBookmaker={preferredBookmaker} visitor={gameData.visitor.fullName} home={gameData.home.fullName}/>
              </Tray>
              <Tray id="totals" title="Over / Under">
                <TotalsLineChart data={gameData.odds} preferredBookmaker={preferredBookmaker} visitor={gameData.visitor.fullName} home={gameData.home.fullName}/>
              </Tray>
            </Tray>
          </div>
          
        </div>
      </CardContent>
    </Card>
  );
}
