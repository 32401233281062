import React from 'react';
import { Box, Typography, Card, CardContent, Grid } from '@material-ui/core';
import { useStyles } from '../v2Components/useStyles.js'; // Ensure the path is correct
import SportsSoccerIcon from '@material-ui/icons/SportsSoccer';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import ViewListIcon from '@material-ui/icons/ViewList';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import HomeIcon from '@material-ui/icons/Home';

export default function Overview() {
  const classes = useStyles();

  const features = [
    {
      icon: <SportsSoccerIcon className={classes.descriptionIcon} />,
      title: 'Select A League',
      description: "Choose from the list of your available leagues to get started."
    },
    {
      icon: <MenuBookIcon className={classes.descriptionIcon} />,
      title: 'Choose Your Stats',
      description: "Generate, Organize, and Access Custom Reports from a Vast Array of Over 700 Stats"
    },
    {
      icon: <ViewListIcon className={classes.descriptionIcon} />,
      title: 'Pick A Game',
      description: "Delve into Detailed Head-to-Head Statistical Analysis for Better Betting Insights"
    },
    {
      icon: <ImportExportIcon className={classes.descriptionIcon} />,
      title: 'Download your Reports',
      description: "Download custom report for upcoming and ongoing Games. Choose CSV, XLSX or JSON format for Easy Integration with Your Own Tools & Offline."
    },
    {
      icon: <HomeIcon className={classes.descriptionIcon} />,
      title: 'Return Home',
      description: "Check back in here for news and tutorials"
    },
  ];

  return (
    <div>
      <Box className={classes.heroContainer}>
        <Box className={classes.imageTextContainer}>
          <img src='/images/overviewHero.png' alt='Stats Overview' className={classes.heroImage5} />
          <Typography variant="h4" className={classes.heroText}>
            Create Your Custom Game Stat Reports
          </Typography>
        </Box>
      </Box>
      <Grid container spacing={4} justifyContent="center">
        {features.map((feature, index) => (
          <Grid item xs={12} sm={6} md={3} key={index}>
            <Card className={classes.featureCard5}>
              <CardContent className={classes.cardContent}>
                <Box className={classes.iconContainer}>
                  {feature.icon}
                </Box>
                <Box className={classes.titleContainer}>
                  <Typography variant="h5">
                    {feature.title}
                  </Typography>
                </Box>
                <Box className={classes.descriptionContainer}>
                  <Typography variant="body1" component="p">
                    {feature.description}
                  </Typography>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </div>
  );
}
