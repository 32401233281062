import React from "react";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import LinkMui from "@material-ui/core/Link";
import { makeStyles } from "@material-ui/core/styles";
import Section from "../components/Section";
import { Link } from "../util/router";

const useStyles = makeStyles((theme) => ({
  sticky: {
    marginTop: "auto",
  },
  wrapper: {
    display: "flex",
    flexWrap: "wrap",
    // width: "100vw",
    marginBottom: 24,
    color: "white",
  },
  item: {
    display: "flex",
    flex: "none",
    justifyContent: "center",
    width: "100%",
    marginBottom: 24,
    [theme.breakpoints.up("sm")]: {
      flex: "50%",
    },
  },
  brand: {
    display: "block",
    height: 32,
  },
  social: {
    alignItems: "flex-end",
  },
  link: {
    color: "white",
    lineHeight: 1,
    "&:not(:last-of-type)": {
      marginRight: "1.2rem",
    },
  },
  left: {
    [theme.breakpoints.up("sm")]: {
      justifyContent: "flex-start",
    },
  },
  right: {
    [theme.breakpoints.up("sm")]: {
      justifyContent: "flex-end",
    },
  },
  smallLinks: {
    [theme.breakpoints.up("sm")]: {
      order: 1,
    },
  },
  legal: {
    opacity: 0.6,
    fontSize: "0.875rem",
    color: "white",
    "& a": {
      color: "inherit",
      marginLeft: "0.8rem",
    },
  },
  subscription: {
    width: "100%",
    textAlign: "center",
    color: "white",
    marginBottom: "24px",
  },
}));

function Footer(props) {
  const classes = useStyles();

  return (
    <Section
      bgColor={props.bgColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
      className={props.sticky && classes.sticky}
    >
      <Container>
        <div className={classes.wrapper}>
          <div className={`${classes.item} ${classes.left}`}>
            <Link to="/">
              <img src={props.logoInverted} alt="Logo" className={classes.brand} />
            </Link>
          </div>
          <div
            className={`${classes.item} ${classes.right} ${classes.smallLinks}`}
          >
            <Typography>
              <LinkMui component={Link} to="/faq" className={classes.link}>
                FAQ
              </LinkMui>
              <LinkMui component={Link} to="/contact" className={classes.link}>
                Contact
              </LinkMui>
              <LinkMui component={Link} to="/tutorials" className={classes.link}>
                Tutorials
              </LinkMui>
            </Typography>
          </div>
          <div className={classes.subscription}>
            <Typography variant="h3">
              Statfactory Subscription: $19/mo.
            </Typography>
          </div>
          <div className={`${classes.item} ${classes.right} ${classes.social}`}>
            {/* Social media links can be added here */}
          </div>
          <span className={`${classes.item} ${classes.legal} ${classes.left}`}>
            {props.copyright}
            <LinkMui component={Link} to="/legal/terms-of-service">
              Terms
            </LinkMui>
            <LinkMui component={Link} to="/legal/privacy-policy">
              Privacy
            </LinkMui>
          </span>
        </div>
      </Container>
    </Section>
  );
}


export default Footer;
