import React, { useState, useMemo } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { Checkbox, FormControlLabel, FormGroup } from '@material-ui/core';
import { useStyles } from './useStyles.js';
import PropTypes from 'prop-types';

const CustomTooltip = ({ active, payload, label }) => {
  const classes = useStyles();
    if (active && payload && payload.length) {
      return (
        <div className={classes.tooltip}>
          <p>{new Date(label).toLocaleString()}</p>
          {payload.map((entry, index) => (
            <p key={index}>
              <span style={{ color: entry.color }}>{entry.name}: {entry.value}</span>
            </p>
          ))}
        </div>
      );
    }
    return null;
  };
  
  CustomTooltip.propTypes = {
    active: PropTypes.bool,
    payload: PropTypes.array,
    label: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.instanceOf(Date),
    ]),
  };

  const generateYAxisTicks = (min, max) => {
    // Calculate the range and ensure it's an absolute value
    const range = Math.abs(max - min);
  
    // Determine the step size, ensuring a minimum step of 1
    const step = Math.max(1, Math.floor(range / 10));
  
    // Calculate the start and end values rounded to the nearest step
    let start = Math.floor(min / step) * step;
    let end = Math.ceil(max / step) * step;

    if (start > end) {
      let temp = start;
      start = end;
      end = temp;
    }
  
    let ticks = [];
  
    // Generate ticks from start to end with the calculated step
    for (let i = start; i <= end; i += step) {
      ticks.push(i);
    }
  
    // If no ticks were generated (e.g., very close min and max), add the min and max
    if (ticks.length === 0) {
      ticks.push(Math.floor(min), Math.ceil(max));
    }
  
    return ticks;
  };

const TotalsLineChart = ({ data, preferredBookmaker }) => {
  const [showOverPoint, setShowOverPoint] = useState(true);
  const [showUnderPoint, setShowUnderPoint] = useState(true);
  const [showOverPrice, setShowOverPrice] = useState(true);
  const [showUnderPrice, setShowUnderPrice] = useState(true);
  
  
  const chartData = data.map(item => {
    const timestamp = new Date(item.timestamp);
    const overPoint = item[preferredBookmaker]?.Totals?.Over?.point;
    const overPrice = item[preferredBookmaker]?.Totals?.Over?.price;
    const underPoint = item[preferredBookmaker]?.Totals?.Under?.point;
    const underPrice = item[preferredBookmaker]?.Totals?.Under?.price;
    return {
      timestamp,
      "Over Point": overPoint,
      "Over Odds": overPrice,
      "Under Point": underPoint * -1,
      "Under Odds": underPrice,
    };
  }).reverse();


  const buffer = .2;
  const formatTime = (timestamp) => {
    const date = new Date(timestamp);
    let hours = date.getHours();
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const ampm = hours >= 12 ? 'p' : 'a';
    
    hours = hours % 12;
    hours = hours ? hours : 12; // The hour '0' should be '12'
    
    return `${hours}:${minutes}${ampm}`;
  };
  
  const filteredData = useMemo(() => {
    return chartData.map(d => {
      const newData = { timestamp: d.timestamp };
      if (showOverPoint) newData["Over Point"] = d["Over Point"] || null;
      if (showUnderPoint) newData["Under Point"] = d["Under Point"] || null;
      if (showOverPrice) newData["Over Odds"] = d["Over Odds"] || null;
      if (showUnderPrice) newData["Under Odds"] = d["Under Odds"] || null;
      return newData;
    });
  }, [showOverPoint, showUnderPoint, showOverPrice, showUnderPrice, chartData]);
  
  const overPointValues = filteredData.map(d => (d["Over Point"]));
  const underPointValues = filteredData.map(d => (d["Under Point"]))
  const overPriceValues = filteredData.map(d => (d["Over Odds"]))
  const underPriceValues = filteredData.flatMap(d => (d["Under Odds"]))

  

  let pointBufferFraction = 0.2;
  let oddsBufferFraction = 0.6;

  let maxPoint = Math.max(...overPointValues, ...underPointValues)
  let minPoint = Math.min(...overPointValues, ...underPointValues)
  let pointBuffer = Math.max(Math.abs(maxPoint), Math.abs(minPoint)) * pointBufferFraction;
  
  maxPoint += pointBuffer;
  minPoint -= pointBuffer;
  
  let maxPrice = Math.max(...overPriceValues, ...underPriceValues)
  let minPrice = Math.min(...overPriceValues, ...underPriceValues)
  
  let priceBuffer = Math.max(Math.abs(maxPrice), Math.abs(minPrice)) * oddsBufferFraction;

  maxPrice += priceBuffer;
  minPrice -= priceBuffer;
  const pointTicks = generateYAxisTicks(minPoint, maxPoint);
  const priceTicks = generateYAxisTicks(minPrice, maxPrice);



  return (
    <>
      <FormGroup row>
        <FormControlLabel
          control={<Checkbox checked={showOverPoint} onChange={() => setShowOverPoint(!showOverPoint)} name="overPoint" />}
          label="Over Point"
        />
        <FormControlLabel
          control={<Checkbox checked={showUnderPoint} onChange={() => setShowUnderPoint(!showUnderPoint)} name="underPoint" />}
          label="Under Point"
        />
        <FormControlLabel
          control={<Checkbox checked={showOverPrice} onChange={() => setShowOverPrice(!showOverPrice)} name="overPrice" />}
          label="Over Odds"
        />
        <FormControlLabel
          control={<Checkbox checked={showUnderPrice} onChange={() => setShowUnderPrice(!showUnderPrice)} name="underPrice" />}
          label="Under Odds"
        />
      </FormGroup>
  
      <ResponsiveContainer width="100%" height={200}>
        <LineChart data={filteredData}>
          <CartesianGrid strokeDasharray="5 7" />
          <XAxis dataKey="timestamp" tickFormatter={(timestamp) => formatTime(timestamp)} />
          <YAxis
            yAxisId="left"
            ticks={pointTicks}
            domain={[minPoint, maxPoint]}
            allowDataOverflow
            orientation="left"
            label={{ value: 'Points', angle: -90, position: 'insideLeft' }}
          />
          <YAxis
            yAxisId="right"
            ticks={priceTicks}
            domain={[minPrice, maxPrice]}
            allowDataOverflow
            orientation="right"
            label={{ value: 'Odds', angle: -90, position: 'insideRight' }}
          />
          <Tooltip content={<CustomTooltip />} />
          <Legend />
          {showOverPoint && <Line isAnimationActive={false} yAxisId="left" type="monotone" dataKey="Over Point" stroke="#d88488" strokeWidth={3} dot={false} />}
          {showUnderPoint && <Line isAnimationActive={false} yAxisId="left" type="monotone" dataKey="Under Point" stroke="#82ca9d" strokeWidth={3} dot={false} />}
          {showOverPrice && <Line isAnimationActive={false} yAxisId="right" type="monotone" dataKey="Over Odds" stroke="#FF7F50" strokeWidth={3} dot={false} />}
          {showUnderPrice && <Line isAnimationActive={false} yAxisId="right" type="monotone" dataKey="Under Odds" stroke="#33B2B2" strokeWidth={3} dot={false} />}    
              </LineChart>
      </ResponsiveContainer>
    </>
  );
  
};

TotalsLineChart.propTypes = {
  data: PropTypes.array.isRequired,
  preferredBookmaker: PropTypes.string.isRequired,
};

export default TotalsLineChart;
