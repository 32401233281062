import React from "react";
import Meta from "./../components/Meta";
import HomeHeroSection from "../v2Components/HomeHeroSection.jsx"
import HomeFeaturesSection from "../v2Components/HomeFeaturesSection.jsx"
// import HeroSection from "./../components/HeroSection";
import ClientsSection from "./../components/ClientsSection";
// import ContentCardsSection from "./../components/ContentCardsSection";
import PricingSection from "./../components/PricingSection";
import TestimonialsSection from "./../components/TestimonialsSection";
import NewsletterSection from "./../components/NewsletterSection";
import analytics from "./../util/analytics";
import { useAuth } from "../util/auth.js";
import GoogleTag from '../components/GoogleTag';
import Button from "@material-ui/core/Button";
import { Link } from "./../util/router";


function IndexPage(props) {
  // Track page view
  React.useEffect(() => {
    analytics.page();
  }, []);

  //TODO: Reset Normal Paywall Access here and in v2../DashboardSection.jsx
  const auth = useAuth();
  // let accessDashboard=
  //     auth.user.planIsActive &&
  //     (
  //         auth.user.planId === "pro" || 
  //         auth.user.planId === "business"
  //     )

    //Uncomment to restrict access to dashboard
    let accessDashboard = false 
    try {
      accessDashboard = auth.user.emailVerified
    } catch (error) {
      // console.log("Error in IndexPage: ", error)
    }

  return (
    <>
      <GoogleTag />
      <Meta />
      <HomeHeroSection 
        buttonPath={!accessDashboard ? "/pricing" : "/dashboard"}
        buttonText = {!accessDashboard ? "Get Started" : "Dashboard"}
        buttonColor="primary"
      />
      <HomeFeaturesSection />
        {/* <HeroSection
          bgColor="default"
          size="medium"
          bgImage=""
          bgImageOpacity={1}
          image="images/HeroImage.png"
          buttonText = {!accessDashboard ? "Get Started" : "Dashboard"}
          buttonColor="primary"
          buttonPath={!accessDashboard ? "/pricing" : "/dashboard"}
        /> */}
      <Button
        component={Link}
        to={
          auth.user
          ? `/purchase/$standard`
          : `/auth/signup?next=/purchase/standard`
        }
        variant="contained"
        color="primary"
        size="large"
        fullWidth={false}
        >
        Start My Free Trial
        </Button>
      <ClientsSection
        bgColor="light"
        size="normal"
        bgImage=""
        bgImageOpacity={0}
        title="Sports Book Odds Data"
        subtitle="Up-to-date odds history for all games on all major US and EU sports books"
      />
      {/* <ContentCardsSection
        bgColor="default"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="Featured Content"
        subtitle=""
      /> */}
      <PricingSection
        bgColor="default"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="Standard Plan Pricing"
        subtitle="One Simple Plans, includes a 7-day free trial."
      />
      {/* <TestimonialsSection
        bgColor="light"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="Here's what people are saying"
        subtitle=""
      /> */}
      <NewsletterSection
        bgColor="default"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="Stay in the know"
        subtitle="We're constantly adding new features, new stats, and new sports. Sign up to be the first to know!"
        buttonText="Subscribe"
        buttonColor="primary"
        inputPlaceholder="Enter your email"
        subscribedMessage="You are now subscribed!"
      />
    </>
  );
}

export default IndexPage;
