import React, { useState, useEffect } from 'react';
import { Typography } from '@material-ui/core';

const CountdownClock = ({ eventTime }) => {
  const calculateTimeLeft = () => {
    const difference = +new Date(eventTime) - +new Date();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearTimeout(timer);
  });

  const formatTime = () => {
    // Destructure the timeLeft object
    const { days, hours, minutes, seconds } = timeLeft;
    // Format the output
    return `${days > 0 ? `${days} Day${days > 1 ? 's' : ''} ` : ''}${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  };

  return (
    <div>
      {Object.keys(timeLeft).length > 0 ? (
        <Typography variant="subtitle1" color="textSecondary">
          Begins in: {formatTime()}
        </Typography>
      ) : (
        <Typography variant="subtitle1">The event has started!</Typography>
      )}
    </div>
  );
};

export default CountdownClock;
