import React, { useContext } from 'react';
import { DashboardContext } from '../components/DashboardContext.js';
import { Select, MenuItem, Typography } from '@material-ui/core';
import { useStyles } from '../v2Components/useStyles.js';

export default function CollectionSelectionDropDown({setActiveView = null}) {
  const { myCollections,
    setActiveCollection,
    activeCollectionName,
    setActiveCollectionName,
    setActiveCollectionDescription,
     } = useContext(DashboardContext);

  const classes = useStyles();

  const handleCollectionChange = (event) => {
    const selectedName = event.target.value;
    const selectedCollection = myCollections.find(collection => collection.name === selectedName)
    setActiveCollection(selectedCollection.stats);
    setActiveCollectionName(selectedCollection.name);
    setActiveCollectionDescription(selectedCollection.description);
    if (setActiveView) {
      setActiveView('Collection');
    }
  };

 

  return (
    <div className={classes.CollectionSelectionDropDown} >
      <Typography variant="body1" >Saved Collections</Typography>
      <Select
        value={activeCollectionName || ''}
        displayEmpty
        onChange={handleCollectionChange}
        variant="outlined"
        className={classes.CollectionSelectionDropDownSelect}
      >
        {myCollections.map((collection, index) => (
          <MenuItem key={index} value={collection.name}>
            {collection.name}
          </MenuItem>
        ))}
      </Select>
     
    </div>
  );
}
