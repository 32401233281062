import React, { useContext } from 'react';

import {
  Grid,
} from '@material-ui/core';

// import { useRouter } from "./../util/router.js";
import VMenu from '../v2Components/VMenu.jsx';
// import PricingSectionBeta from './PricingSectionBeta.js';

import PricingSection from './PricingSection.js';

import Overview from '../v2Components/Overview.jsx'
import Leagues from '../v2Components/Leagues.jsx';
import Games from '../v2Components/Games.jsx';
import { useAuth } from "../util/auth.js";
import { useStyles } from '../v2Components/useStyles.js';
import StatsExplorer from '../v2Components/StatsExplorer.jsx';
import GameDetail from '../v2Components/GameDetail.jsx';
import { DashboardContext } from './DashboardContext.js'

function Dashboard() {
    // const router = useRouter();
    const auth = useAuth();
    const classes = useStyles();

    const { leagues, 
      activeLeague, 
      activeView, setactiveView,
      activeCollection,
      isDictionaryOpen, setDictionaryOpen,
      gameProps
    } = useContext(DashboardContext);

    const toggleDictionary = () => {
      setDictionaryOpen(!isDictionaryOpen);
    };
    console.log(auth.user)
  const Stage = () => {
      let content;
    
      switch (activeView) {
        case 'Leagues':
          
          content = <Leagues/>
          break;
        case 'Games':
          content = <Games/>;
          break;
        case 'Game':
          content = <GameDetail game={gameProps} filter={activeCollection} />;
          // content = <GameView game={gameProps} filter={activeCollection} />;
          break;
        case 'Overview':
          content = <Overview />;
          break;
        // ... handle other cases
        default:
          content = <Overview />
      }
    
      return <div>{content}</div>;
  };
  
        return (
          
            <Grid container className={classes.root}>
              <Grid item className={classes.leftPanel}>
                <VMenu
                    onMenuSelect={setactiveView} 
                    availableLeagues={leagues}
                    activeLeague={activeLeague}
                    onDictionaryClick={toggleDictionary}
                />
              </Grid>
              <Grid item className={classes.rightPanel}>
              <Stage />
              <StatsExplorer open={isDictionaryOpen} onClose={() => setDictionaryOpen(false)} />
              </Grid>
            </Grid>
          );
        
    }

export default Dashboard;
