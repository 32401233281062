import React from 'react';
import { Card, CardContent, Typography } from '@material-ui/core';
import { useStyles } from '../v2Components/useStyles';

function calcImpliedProbability(odds) {
  if (typeof odds !== 'number') {
    return odds;
  }

  let impliedProbability;

  if (odds > 0) {
    impliedProbability = 100 / (odds + 100) * 100;
  } else {
    impliedProbability = (-odds) / ((-odds) + 100) * 100;
  }

  return impliedProbability.toFixed(2);
}

const TeamOddsRow = ({ team }) => {
  const primaryColor = team.primaryColor;
  const secondaryColor = team.secondaryColor;
  const classes = useStyles();
  return (
    <Card style={{ width: '100%', display: 'flex' }}>
      <div style={{ backgroundColor: primaryColor, width: 50, marginRight: '.5em'  }}></div>
      <div style={{ backgroundColor: secondaryColor, width: 20, marginRight: '.5em' }}></div>
      <CardContent style={{ display: 'flex', alignItems: 'center', flex: 1, padding: 4 }}>
        <div className={classes.teamOddsRowContainer} style={{ display: 'flex', width: '100%' }}>
          <div style={{ display: 'flex', flexDirection: "row", width:"40%" }}>

          <div className={classes.teamOddsTypeContainer} style={{ flex: '1 1 60%' }}>
            <Typography className={classes.league}>{team.league}</Typography>
            <Typography className={classes.teamName}>{team.teamName}</Typography>
            <Typography variant="body2" color="textSecondary">
              implied probability %
            </Typography>
          </div>
          <div className={classes.teamOddsTypeContainer} style={{ flex: '1 1 10%' }}>
            <Typography className={classes.oddsLabel}>
              Score:
            </Typography>
            <Typography variant="h5">{team.results || "-"}</Typography>
           
          </div>
          </div>
          <div className={classes.teamOddsTypeContainer} style={{ flex: '1 1 10%' }}>
            <Typography className={classes.oddsLabel}>
              Money Line
            </Typography>
            <Typography className={classes.oddsValue}>
              {team.moneylineOdds > 0 ? `+${team.moneylineOdds}` : team.moneylineOdds}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              {calcImpliedProbability(team.moneylineOdds)}%
            </Typography>
          </div>
          <div className={classes.teamOddsTypeContainer} style={{ flex: '1 1 10%' }}>
            <Typography className={classes.oddsLabel}>
              Spread
            </Typography>
            <Typography className={classes.oddsValue}>
              {team.spreadPoint > 0 ? `+${team.spreadPoint}` : `${team.spreadPoint}`} / {team.spreadPrice > 0 ? `+${team.spreadPrice}` : `${team.spreadPrice}`}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              {calcImpliedProbability(team.spreadPrice)}%
            </Typography>
          </div>
          <div className={classes.teamOddsTypeContainer} style={{ flex: '1 1 10%' }}>
            <Typography className={classes.oddsLabel}>
              Over-Under
            </Typography>
            <Typography className={classes.oddsValue}>
              {team.totalsPoints > 0 ? `+${team.totalsPoints}` : `${team.totalsPoints}`} / {team.totalsPrice > 0 ? `+${team.totalsPrice}` : `${team.totalsPrice}`}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              {calcImpliedProbability(team.totalsPrice)}%
            </Typography>
          </div>
        </div>
      </CardContent>
    </Card>
  );
};

export default TeamOddsRow;
