import React, { useCallback, useContext } from 'react';
import TreeView from '@material-ui/lab/TreeView';
import TreeItem from '@material-ui/lab/TreeItem';
import { Switch } from '@material-ui/core';
import FolderIcon from '@material-ui/icons/Folder';
import FolderOpenIcon from '@material-ui/icons/FolderOpen';
import { DashboardContext } from '../components/DashboardContext';
import { useStyles } from '../v2Components/useStyles';

const DynamicTreeView = ({ expandedNodes, toggleNode, setActiveView }) => {
    const classes = useStyles();
    
    const {
        dictionary, activeCollection, setActiveCollection, setSelectedStat
    } = useContext(DashboardContext);
    
    const handleStatToggle = useCallback((UUID) => {
        const updatedCollection = activeCollection.includes(UUID)
            ? activeCollection.filter(item => item !== UUID)
            : [...activeCollection, UUID];
        setActiveCollection(updatedCollection);
    }, [activeCollection, setActiveCollection]);

    const handleToggle = (event, nodeIds) => {
        toggleNode(nodeIds); // nodeIds should be an array of node IDs
    };

    const handleClick = (nodeId, nodes) => {
        if (!nodes.children) {
            setSelectedStat(nodeId);
            setActiveView('StatDetail');
        }
    };

    function transformDataToTree(data, parentNodeId = 'root') {
        let nodes = [];
        Object.keys(data).forEach(key => {
            const value = data[key];
            const nodeId = `${parentNodeId}-${key}`;  // Generate a stable ID for each node
            if (typeof value === 'object' && value !== null && !Array.isArray(value)) {
                const childNodes = transformDataToTree(value, nodeId);
                nodes.push(createTreeNode(nodeId, key, childNodes));
            } else {
                nodes.push(createTreeNode(value, key));
            }
        });

        // Sort nodes so that folders come first and switches come last
        nodes.sort((a, b) => (b.children ? 1 : 0) - (a.children ? 1 : 0));

        return nodes;
    }

    function createTreeNode(id, name, children = null) {
        return { id, name, children };
    }

    const renderTree = (nodes) => {
        const isExpanded = expandedNodes.includes(nodes.id);

        return (
            <TreeItem
                key={nodes.id}
                nodeId={nodes.id}
                label={
                    <div className={classes.treeItemLabel}>
                        {nodes.children ? (
                            isExpanded ? <FolderOpenIcon className={classes.folderIcon} /> : <FolderIcon className={classes.folderIcon} />
                        ) : (
                            <div className={classes.switchWrapper}>
                                <Switch
                                    checked={activeCollection.includes(nodes.id)}
                                    onChange={() => handleStatToggle(nodes.id)}
                                    classes={{
                                        switchBase: classes.switchBase,
                                        checked: classes.switchChecked,
                                        track: classes.switchTrack
                                    }}/>
                            </div>
                        )}
                        {nodes.name}
                    </div>
                }
                onClick={(event) => {
                    event.stopPropagation();  // Stop event bubbling
                    handleClick(nodes.id, nodes);
                }}
            >
                {Array.isArray(nodes.children) ? nodes.children.map(node => renderTree(node)) : null}
            </TreeItem>
        );
    };

    const treeData = transformDataToTree(dictionary);

    return (
        <TreeView
            expanded={expandedNodes}
            onNodeToggle={handleToggle}
            defaultExpanded={['root']}
            style={{ flexGrow: 1, maxWidth: 400, overflowY: 'scroll' }}
        >
            {treeData.map(node => renderTree(node))}
        </TreeView>
    );
};

export default DynamicTreeView;
