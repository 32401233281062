import React, { useContext } from 'react';
import { Card, CardMedia } from '@material-ui/core';
import { useStyles } from './useStyles';
import { DashboardContext } from '../components/DashboardContext';

export default function Leagues() {

  const classes = useStyles();
  const { 
    leagues, setActiveLeague, 
    setactiveView
  } = useContext(DashboardContext);

  const getImageForLeague = (leagueName) => {
    try {
      return require(`./${leagueName}.png`);
    } catch (e) {
      console.error("Failed to load image for league:", leagueName);
      // Return a default image or handle the error as needed
      return null
    }
  };

  return (
    <div className={classes.LeaguesContainer}>
      {leagues.map((league, index) => (
        <Card key={index} className={classes.LeaguesCard} onClick={() => {
          
          setActiveLeague(league);
          setactiveView('Games');
        }}>
          <CardMedia
            component="img"
            alt={`${league } logo`}
            className={classes.LeaguesMedia}
            image={getImageForLeague(league)}
            title={league}
          />
        </Card>
      ))}
    </div>
  );
}
