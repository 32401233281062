import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  container: {
    position: 'relative',
    alignContent: 'center',
    maxWidth: '1000px',
    width: '100%',
    margin: '1.5em',
    overflow: 'hidden',
  },
  image: {
    width: '100%',
    height: 'auto',
    display: 'block',
    borderRadius: 10,
    backgroundColor: '#000',
  },
  text: {
    position: 'absolute',
    width: '50%',
    top: '50%',
    color: '#fff',
    transition: 'opacity 0.2s ease-out, top 0.5s ease-out', // Reduced opacity transition duration
    pointerEvents: 'none',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    padding: 20,
    borderRadius: 5,
    textAlign: (props) => (props.align === 'left' ? 'left' : props.align === 'right' ? 'right' : 'center'),
    left: (props) => (props.align === 'left' ? '3%' : 'auto'),
    right: (props) => (props.align === 'right' ? '3%' : 'auto'),
    transform: (props) => 
      props.align === 'left' 
        ? 'translateY(-50%)' 
        : props.align === 'right' 
          ? 'translateY(-50%)' 
          : 'translate(-50%, -50%)'
  },
}));

const ParallaxComponent = ({ text, imageSrc, id, align }) => {
  const classes = useStyles({ align });
  const [opacity, setOpacity] = useState(1);
  const [top, setTop] = useState('50%');

  useEffect(() => {
    const handleScroll = () => {
      const imageElement = document.getElementById(`parallax-${id}`);
      const rect = imageElement.getBoundingClientRect();
      const windowHeight = window.innerHeight;

      const imageMiddle = rect.top + rect.height / 2;
      const windowMiddle = windowHeight / 2;

      if (imageMiddle > windowMiddle && imageMiddle < windowHeight + windowMiddle) {
        setOpacity(1);
      } else if (window.scrollY < windowMiddle / 2) { // Add condition for window's scroll position
        setOpacity(0);
      }

      const offset = windowMiddle - imageMiddle;
      setTop(`calc(50% + ${offset}px)`);
    };

    const handleResize = () => {
      handleScroll();
    };

    window.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', handleResize);

    handleScroll();

    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', handleResize);
    };
  }, [id]);

  return (
    <div className={classes.container}>
      <img id={`parallax-${id}`} src={imageSrc} alt="Parallax" className={classes.image} />
      <Typography variant="h3" className={classes.text} style={{ opacity, top }}>
        {text}
      </Typography>
    </div>
  );
};

ParallaxComponent.propTypes = {
  id: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  imageSrc: PropTypes.string.isRequired,
  align: PropTypes.oneOf(['left', 'right', 'center']),
};

ParallaxComponent.defaultProps = {
  align: 'center',
};

export default ParallaxComponent;
