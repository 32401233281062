import React, { useContext }  from 'react';
import { Card, Typography } from '@material-ui/core';
import { useStyles } from './useStyles.js';
import { DashboardContext } from '../components/DashboardContext.js';
import TeamOddsRow from './TeamOddsRow.jsx';
import CountdownClock from './CountdownClock.jsx';

function GameSummary({game}) {
  const {
    setactiveView,
    setGameProps,
    preferredBookmaker,
  } = useContext(DashboardContext);

  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit', timeZoneName: 'short' };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  const classes = useStyles();

let moneyLinePresent = game.Odds[preferredBookmaker]?.MoneyLine && game.Odds[preferredBookmaker].MoneyLine[game.Visitor.fullName] != null;
let spreadPresent = game.Odds[preferredBookmaker]?.Spreads && game.Odds[preferredBookmaker].Spreads[game.Visitor.fullName] && game.Odds[preferredBookmaker].Spreads[game.Visitor.fullName] ? true : false;
let OUPresent = game.Odds[preferredBookmaker]?.Totals && game.Odds[preferredBookmaker].Totals.Over && game.Odds[preferredBookmaker].Totals.Over.price ? true : false;
let team1 = {
  teamName: game.Visitor.fullName,
  league: `${game.Visitor.league || game.Visitor.conference || ""} ${game.Visitor.division}`,
  moneylineOdds: moneyLinePresent ? game.Odds[`${preferredBookmaker}`].MoneyLine[game.Visitor.fullName] : 'N/A',
  spreadPoint: spreadPresent ? game.Odds[`${preferredBookmaker}`].Spreads[game.Visitor.fullName].point : 'N/A',
  spreadPrice: spreadPresent ? game.Odds[`${preferredBookmaker}`].Spreads[game.Visitor.fullName].price : 'N/A',
  totalsPoints: OUPresent ? game.Odds[`${preferredBookmaker}`].Totals.Over.point : 'N/A',
  totalsPrice: OUPresent ? game.Odds[`${preferredBookmaker}`].Totals.Over.price : 'N/A',
  primaryColor: game.Visitor.primaryColor,
  secondaryColor: game.Visitor.secondaryColor,
  results: !Array.isArray(game.results) ? null : game.results[0].name === game.Visitor.fullName ? `${game.results[0].score}` : `${game.results[1].score}`
};
let team2 = {
  teamName: game.Home.fullName,
  league: `${game.Home.league || game.Home.conference || ""} ${game.Home.division}`,
  moneylineOdds: moneyLinePresent ? game.Odds[`${preferredBookmaker}`].MoneyLine[game.Home.fullName] : 'N/A',
  spreadPoint: spreadPresent ? game.Odds[`${preferredBookmaker}`].Spreads[game.Home.fullName].point : 'N/A',
  spreadPrice: spreadPresent ? game.Odds[`${preferredBookmaker}`].Spreads[game.Home.fullName].price : 'N/A',
  totalsPoints: OUPresent ? game.Odds[`${preferredBookmaker}`].Totals.Under.point*-1 : 0,
  totalsPrice: OUPresent ? game.Odds[`${preferredBookmaker}`].Totals.Under.price : 0,
  primaryColor: game.Home.primaryColor,
  secondaryColor: game.Home.secondaryColor,
  results: !Array.isArray(game.results) ? null : (game.results[1].name === game.Home.fullName ? `${game.results[1].score}` : `${game.results[0].score}`)
};



return (
    <Card className={classes.card} onClick={() => {
      setactiveView('Game')
      setGameProps(game.OddsId)
    }
      
    }>
      <div className={classes.horizontalTimeLayout}>
        <Typography variant="subtitle1" color="textSecondary" className={classes.timestamp}>
          {formatDate(game.EventTime) }
            
        </Typography>
        <CountdownClock eventTime={game.EventTime}/>
      </div>
      <TeamOddsRow team={team1}/>
      <TeamOddsRow team={team2}/>
    </Card>
  );
}

export default GameSummary;
