import React from "react";
import Meta from "./../components/Meta";
import DashboardSection from "./../components/DashboardSection";
import { requireAuth } from "./../util/auth";
import { DataProvider } from "./../components/DashboardContext"; // Ensure correct import

function DashboardPage(props) {
  return (
    <>
      <Meta title="StatFactory Dashboard" />
      <DashboardSection
        bgColor="default"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="Dashboard"
        subtitle=""
      />
    </>
  );
}

// Wrap DashboardPage with requireAuth and then DataProvider
const WrappedDashboardPage = requireAuth(DashboardPage);

function Dashboard(props) {
  return (
    <DataProvider>
      <WrappedDashboardPage {...props} />
    </DataProvider>
  );
}

export default Dashboard;
