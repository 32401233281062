import React from "react";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import Section from "./Section";
import SectionHeader from "./SectionHeader";
import topImage from "../v2Components/images/MoneylineChart.png"
import bottomImage from "../v2Components/images/featureChart.png"
import { useStyles } from '../v2Components/useStyles';

function ClientsSection(props) {
  const classes = useStyles();
  const items = [
    {
      name: "Draft Kings",
      image: "/images/Draftkings.png",
      width: "150px",
    },
    {
      name: "BetMGM",
      image: "/images/betmgm.png",
      width: "150px",
    },
    {
      name: "Pinnacle",
      image: "/images/pinnacle.jpg",
      width: "150px",
    },
    {
      name: "FanDuel",
      image: "/images/fanduel_lg_hrz_rgb_blu_pos.png",
      width: "150px",
    },
  ];

  return (
    <>
    <Section
      bgColor={props.bgColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
      >
      <Container>
        <Box textAlign="center">
          <SectionHeader
            title={props.title}
            subtitle={props.subtitle}
            size={4}
            textAlign="center"
            />
          <Grid container={true} justifyContent="center">
            {items.map((item, index) => (
              <Grid item={true} xs={12} md="auto" key={index}>
                <Card className={classes.featureCard2}>
                <Box py={2} px={3}>
                  <img src={item.image} width={item.width} alt={item.name} />
                </Box>
              </Card>
              </Grid>
            ))}
          </Grid>
         
        </Box>
      </Container>
      
    </Section>
    {/* <Section
      bgColor={props.bgColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
      > */}
      <Container>
        <Box textAlign="center" mt={2}>
          <SectionHeader
            title='Odds History Charts'
            subtitle='Track changes in odds over time from multiple sportsbooks'
            size={4}
            textAlign="center"
            />
          <Grid container={true} justifyContent="center">
              <Grid item={true} xs={12} md="auto" key="1">
                <Card className={classes.featureCard3}>
                <Box py={2} px={3}>
                  <img src={bottomImage} width='auto' alt={`Spread Chart`} />
                </Box>
              </Card>
              </Grid>
          </Grid>
         
        </Box>
      </Container>
      
    {/* </Section> */}
                </>
  );
}

export default ClientsSection;

<SectionHeader
title='Odds History Charts'
subtitle='Track changes in odds over time from multiple sportsbooks'
size={4}
textAlign="center"
/>
{bottomImage && (
<Box mt={4}>
  <img src={bottomImage} width="100%" alt="Bottom Image" />
</Box>
)}