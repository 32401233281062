import React, { useContext } from 'react';
import { DashboardContext } from '../components/DashboardContext.js';
import { IconButton } from '@material-ui/core';
import AddCircleIcon from '@material-ui/icons/AddCircle'; // Import the add icon
import { useAuth } from "../util/auth.js";

export default function AddNewCollectionButton() {
  const auth = useAuth();
    const { myCollections,
        setActiveCollection,
        setActiveCollectionName,
        setActiveCollectionDescription,
        setMyCollections } = useContext(DashboardContext);

 // Handler for adding a new collection
 const handleAddNewCollection = () => {
  if (auth.user.planIsActive) {

    const newCollectionName = `New Collection ${myCollections.length + 1} Name`; // Modify this as needed, possibly with a unique identifier
    const newCollection = {
      name: newCollectionName,
      collection: [],
      description: 'New Collection Description', // Modify or generate dynamically as needed
    };
    setMyCollections([...myCollections, newCollection]);
    setActiveCollection([]);
    setActiveCollectionName(newCollectionName);
    setActiveCollectionDescription(newCollection.description);
    }  else {
      alert('You must have an active subscription to maintain more than one collection.');
    }
  };


  return (
    <IconButton onClick={handleAddNewCollection} style={{ color: 'green', padding: '5px' }}>
      <AddCircleIcon style={{ fontSize: '36px' }} />
    </IconButton>
    )
}
