import React from 'react';
import { Grid, Typography, Container, Card } from '@material-ui/core';
import { useStyles } from './useStyles';
import SportsIcon from '@material-ui/icons/Sports';
import { useDarkMode } from "./../util/theme";

const FeaturesSection = () => {
  const classes = useStyles();
  
  const darkMode = useDarkMode();
  const feature1 =  darkMode.value ? '/images/feature1Dark.png' : '/images/feature1Light.png';
  const feature2 =  darkMode.value ? '/images/feature2Dark.png' : '/images/feature2Light.png';
  const feature3 =  darkMode.value ? '/images/feature3Dark.png' : '/images/feature3Light.png';
  const feature4 =  darkMode.value ? '/images/feature4Dark.png' : '/images/feature4Light.png';

  const features = [
    { title: 'Extensive Data...', description: 'Thousands of Data Choices.', icon: <SportsIcon />, image: feature1 },
    { title: 'That You Choose From...', description: 'Choose As Many As You Want', icon: <SportsIcon />, image: feature2 },
    { title: 'For Custom Game Reports...', description: 'Every League Game, Current Stats', icon: <SportsIcon />, image: feature3 },
    { title: 'That You Can Download!', description: 'XLSX and CSV for Offline Analysis', icon: <SportsIcon />, image: feature4 },
  ];

  return (
    <Container className={classes.featuresSection}>
      <Grid container spacing={4}>
        {features.map((feature, index) => (
          <Grid item xs={12} sm={6} md={3} key={index}>
            <div className={classes.featureItem}>
          <Card className={classes.featureCard}>
              <Typography variant="h6" >
                {feature.title}
              </Typography>
              <Typography variant="body1" component="p">
                {feature.description}
              </Typography>
            </Card>
            <img src={feature.image} alt={feature.title} className={classes.featureImage} />
            </div>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default FeaturesSection;
