import React, { useState, useMemo } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { Checkbox, FormControlLabel, FormGroup } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useStyles } from './useStyles.js';


const CustomTooltip = ({ active, payload, label }) => {
const classes = useStyles();
  if (active && payload && payload.length) {
    return (
      <div className={classes.tooltip}>
        <p>{new Date(label).toLocaleString()}</p>
        {payload.map((entry, index) => (
          <p key={index}>
            <span style={{ color: entry.color }}>{entry.name}: {entry.value}</span>
          </p>
        ))}
      </div>
    );
  }
  return null;
};

CustomTooltip.propTypes = {
  active: PropTypes.bool,
  payload: PropTypes.array,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.instanceOf(Date),
  ]),
};

const generateYAxisTicks = (min, max) => {
  // Calculate the range and ensure it's an absolute value
  const range = Math.abs(max - min);

  // Determine the step size, ensuring a minimum step of 1
  const step = Math.max(1, Math.floor(range / 10));

  // Calculate the start and end values rounded to the nearest step
  let start = Math.floor(min / step) * step;
  let end = Math.ceil(max / step) * step;

  if (start > end) {
    let temp = start;
    start = end;
    end = temp;
  }

  let ticks = [];

  // Generate ticks from start to end with the calculated step
  for (let i = start; i <= end; i += step) {
    ticks.push(i);
  }

  // If no ticks were generated (e.g., very close min and max), add the min and max
  if (ticks.length === 0) {
    ticks.push(Math.floor(min), Math.ceil(max));
  }

  return ticks;
};

const SpreadLineChart = ({ data, preferredBookmaker, visitor, home }) => {
  const [showVisitorPoint, setShowVisitorPoint] = useState(true);
  const [showHomePoint, setShowHomePoint] = useState(true);
  const [showVisitorPrice, setShowVisitorPrice] = useState(true);
  const [showHomePrice, setShowHomePrice] = useState(true);

  const classes = useStyles();

  const chartData = data.map(item => {
    const timestamp = item.timestamp;
    const visitorPoint = item[preferredBookmaker]?.Spreads?.[visitor]?.point;
    const visitorPrice = item[preferredBookmaker]?.Spreads?.[visitor]?.price;
    const homePoint = item[preferredBookmaker]?.Spreads?.[home]?.point;
    const homePrice = item[preferredBookmaker]?.Spreads?.[home]?.price;
    return { timestamp, [`${visitor} Point`]: visitorPoint, [`${visitor} Odds`]: visitorPrice, [`${home} Point`]: homePoint, [`${home} Odds`]: homePrice };
  }).reverse(); // Reverse the array to have the most recent record on the left

  const formatTime = (timestamp) => {
    const date = new Date(timestamp);
    let hours = date.getHours();
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const ampm = hours >= 12 ? 'p' : 'a';
    
    hours = hours % 12;
    hours = hours ? hours : 12; // The hour '0' should be '12'
    
    return `${hours}:${minutes}${ampm}`;
  };

  const filteredData = useMemo(() => {
    return chartData.map(d => {
      const newData = { timestamp: d.timestamp };
      if (showVisitorPoint) newData[`${visitor} Point`] = d[`${visitor} Point`];
      if (showHomePoint) newData[`${home} Point`] = d[`${home} Point`];
      if (showVisitorPrice) newData[`${visitor} Odds`] = d[`${visitor} Odds`];
      if (showHomePrice) newData[`${home} Odds`] = d[`${home} Odds`];
      return newData;
    });
  }, [showVisitorPoint, showHomePoint, showVisitorPrice, showHomePrice, chartData, visitor, home]);

  const vPointValues = filteredData.flatMap(d => (d[`${visitor} Point`] != null) ? d[`${visitor} Point`] : []);
  const hPointValues = filteredData.flatMap(d => (d[`${home} Point`] != null) ? d[`${home} Point`] : []);
  const vPriceValues = filteredData.flatMap(d => (d[`${visitor} Odds`] != null) ? d[`${visitor} Odds`] : []);
  const hPriceValues = filteredData.flatMap(d => (d[`${home} Odds`] != null) ? d[`${home} Odds`] : []);
  let pointBufferFraction = 0.2;
  let oddsBufferFraction = 0.6;

  let maxPoint = Math.max(...vPointValues, ...hPointValues)
  let minPoint = Math.min(...vPointValues, ...hPointValues)
  let pointBuffer = Math.max(Math.abs(maxPoint), Math.abs(minPoint)) * pointBufferFraction;
  
  maxPoint += pointBuffer;
  minPoint -= pointBuffer;
  
  let maxPrice = Math.max(...vPriceValues, ...hPriceValues)
  let minPrice = Math.min(...vPriceValues, ...hPriceValues)
  
  let priceBuffer = Math.max(Math.abs(maxPrice), Math.abs(minPrice)) * oddsBufferFraction;

  maxPrice += priceBuffer;
  minPrice -= priceBuffer;
  const pointTicks = generateYAxisTicks(minPoint, maxPoint);
  const priceTicks = generateYAxisTicks(minPrice, maxPrice);

  return (
    <>
      <FormGroup row>
        <FormControlLabel
          control={<Checkbox checked={showVisitorPoint} onChange={() => setShowVisitorPoint(!showVisitorPoint)} name="visitorPoint" />}
          label={`${visitor} Point`}
        />
        <FormControlLabel
          control={<Checkbox checked={showHomePoint} onChange={() => setShowHomePoint(!showHomePoint)} name="homePoint" />}
          label={`${home} Point`}
        />
        <FormControlLabel
          control={<Checkbox checked={showVisitorPrice} onChange={() => setShowVisitorPrice(!showVisitorPrice)} name="visitorPrice" />}
          label={`${visitor} Odds`}
        />
        <FormControlLabel
          control={<Checkbox checked={showHomePrice} onChange={() => setShowHomePrice(!showHomePrice)} name="homePrice" />}
          label={`${home} Odds`}
        />
      </FormGroup>

      <ResponsiveContainer width="100%" height={200}>
        <LineChart
          data={filteredData}
        >
          <CartesianGrid strokeDasharray="5 7" />
          <XAxis dataKey="timestamp" tickFormatter={(timestamp) => formatTime(timestamp)} />
          <YAxis yAxisId="left" ticks={pointTicks} domain={[minPoint, maxPoint]} allowDataOverflow orientation="left" label={{ value: 'Point', angle: -90, position: 'insideLeft' }} />
          <YAxis yAxisId="right" ticks={priceTicks} domain={[minPrice, maxPrice]} allowDataOverflow orientation="right" label={{ value: 'Odds', angle: -90, position: 'insideRight' }} />
          <Tooltip content={<CustomTooltip />} />
          <Legend />
          {showVisitorPoint && <Line isAnimationActive={false} yAxisId="left" type="monotone" dataKey={`${visitor} Point`} stroke="#d88488" strokeWidth={3} dot={false} />}
          {showHomePoint && <Line isAnimationActive={false} yAxisId="left" type="monotone" dataKey={`${home} Point`} stroke="#82ca9d" strokeWidth={3} dot={false} />}
          {showVisitorPrice && <Line isAnimationActive={false} yAxisId="right" type="monotone" dataKey={`${visitor} Odds`} stroke="#FF7F50" strokeWidth={3} dot={false} />}
          {showHomePrice && <Line isAnimationActive={false} yAxisId="right" type="monotone" dataKey={`${home} Odds`} stroke="#33B2B2" strokeWidth={3} dot={false} />}
        </LineChart>
      </ResponsiveContainer>
    </>
  );
};

export default SpreadLineChart;
