import React, {useContext, } from 'react';
import { List, ListItem, ListItemIcon, Tooltip, Typography } from '@material-ui/core';
import { DashboardContext } from '../components/DashboardContext.js'
import { useStyles } from './useStyles.js';
// Import icons as needed
import HomeIcon from '@material-ui/icons/Home';
import SettingsIcon from '@material-ui/icons/Settings';
import ViewListIcon from '@material-ui/icons/ViewList'; // Icon for leagues
import SportsSoccerIcon from '@material-ui/icons/SportsSoccer'; // Icon for games
import { GroupSharp } from '@material-ui/icons';
import MenuBookIcon from '@material-ui/icons/MenuBook'; 
import NightsStayIcon from "@material-ui/icons/NightsStay";
import WbSunnyIcon from "@material-ui/icons/WbSunny";
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { useAuth } from "../util/auth.js";
import { useRouter } from "./../util/router.js";
import { useDarkMode } from "./../util/theme";

const VMenu = ({onMenuSelect, onDictionaryClick }) => {
  const auth = useAuth();
  const router = useRouter();
  const darkMode = useDarkMode()
  const classes = useStyles();
  
  const { leagues, 
    activeLeague, activeCollection, 
    isDictionaryOpen, setDictionaryOpen,

  } = useContext(DashboardContext);

  const toggleDictionary = () => {
    setDictionaryOpen(!isDictionaryOpen);
  };

  //Top Left Menu Items
  let topIconMap = [
    {
      text: 'Dashboard Overview',
      icon: <HomeIcon className={classes.menuIcon}/>,
      conditional: true,
      onClick: () => onMenuSelect('Overview')
    },
    {
      text: 'Select A League',
      icon: activeLeague ? <SportsSoccerIcon className={classes.menuIcon}/> : <SportsSoccerIcon className={`${classes.menuIcon}  ${classes.iconWrapper}`}/>,
      conditional: !!leagues,
      onClick: () => onMenuSelect('Leagues')
    },
    {
      text: `${activeLeague} Data Explorer`,
      icon: activeCollection?.length > 1 ? <MenuBookIcon className={classes.menuIcon}/> : <MenuBookIcon className={`${classes.menuIcon}  ${classes.iconWrapper}`}/>,
      conditional: !!activeLeague,
      onClick: toggleDictionary
    },
    {
      text: `${activeLeague} Games`,
      icon: <ViewListIcon className={classes.menuIcon}/>,
      conditional: !!activeLeague,
      onClick: () => onMenuSelect('Games')
    },
    {
      text: `${activeLeague} Team Explorer`,
      icon: <GroupSharp className={classes.menuIcon}/>,
      conditional: !!activeLeague,
      onClick: () => onMenuSelect('Teams')
    },
  ]
  //Bottom Left Menu Items
  let bottomIconMap = [
    {
      text: `Settings`,
      icon: <SettingsIcon className={classes.menuIcon}/>,
      conditional: true,
      onClick: () => router.push('/settings/general')
      
    },
    {
      text: `Toggle Light / Dark Mode`,
      icon: darkMode.value ? <NightsStayIcon className={classes.menuIcon}/> : <WbSunnyIcon className={classes.menuIcon}/>,
      conditional: true,
      onClick: () => {
        console.log("Toggling Dark Mode", darkMode);
        darkMode.toggle();
      }
    },
    {
      text: `Log out of StatFactory.io`,
      icon: <ExitToAppIcon className={classes.menuIcon}/>,
      conditional: auth.user,
      onClick: () => auth.signout()
    },

  ]
  return (
    // <Grid item xs={1} className={classes.fullHeight}>
       <div className={classes.flexContainer}>
           <List >
           {topIconMap.map((icon, index) => (
              icon.conditional && (
                <ListItem button key={index} onClick={icon.onClick} >
                  {index === 1 && activeLeague === null ? (
                      <Tooltip
                        placement="right"
                        title={
                          <React.Fragment>
                            <Typography color="inherit">{icon.text}</Typography>
                          </React.Fragment>
                          }
                        classes={{ tooltip: classes.htmlTooltip }}
                      >
                      <ListItemIcon className={classes.menuIcon}>{icon.icon}</ListItemIcon>
                    </Tooltip>
                  ) : (
                  <Tooltip
                    placement="right"
                    title={
                      <React.Fragment>
                        <Typography color="inherit">{icon.text}</Typography>
                      </React.Fragment>
                    }
                    classes={{ tooltip: classes.htmlTooltip }}
                  >
          <ListItemIcon className={classes.menuIcon}>{icon.icon}</ListItemIcon>
        </Tooltip>
      )}
    </ListItem>
  )
))}

          </List>
           <List >
            {bottomIconMap.map((icon, index) => (
              icon.conditional && (
                <ListItem button key={index} onClick={icon.onClick}>
                  <Tooltip
                    placement="right"
                    title={
                      <React.Fragment>
                        <Typography color="inherit">{icon.text}</Typography>
                      </React.Fragment>
                    }
                    classes={{ tooltip: classes.htmlTooltip }}
                    >
                    <ListItemIcon className={classes.menuIcon}>{icon.icon}</ListItemIcon>
                  </Tooltip>
              </ListItem>
            )
          ))}
        </List>
      </div>
    // </Grid>
  );
};

export default VMenu;