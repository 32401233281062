import React, { useContext } from 'react'
import GameSummary from './GameSummary'
import { Typography} from '@material-ui/core';
import BookmakerSelectionDropDown from './BookmakerSelectionDropDown.jsx';
import { useStyles } from '../v2Components/useStyles.js';
import { DashboardContext } from '../components/DashboardContext.js';

export default function Games() {
  const {
    odds,
  } = useContext(DashboardContext);
  
  function CustomDividerWithHeader({ headerText }) {
    const classes = useStyles();
    
    return (
      <div className={classes.divider}>
        <Typography variant="subtitle1" className={classes.gamesSubHeader}>
          {headerText}
        </Typography>
      </div>
    );
  }
  
  if (!odds || odds.length === 0) {
    return (
      <div>
        <h1>Loading...</h1>
      </div>
    )
  } else {
   return (
    <>
    <BookmakerSelectionDropDown />
      <CustomDividerWithHeader headerText="Ongoing Games" />
      {odds.map((game, index) => {
          if (game.Status === 'active') {
            return <GameSummary key={index} game={game}/>;
          }
          return null;
        })}
      <CustomDividerWithHeader headerText="Upcoming Games" />
      {odds.map((game, index) => {
          if (game.Status === 'upcoming') {
            return <GameSummary key={index} game={game}/>;
          }
          return null;
        })}
    </>
  );
  }
}
